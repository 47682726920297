import { StatusMessageBlacklisted, type ApiErrorClass } from '~/types/apiError'
import { AbortNavigationMessage } from '~/types/error'

const retrieveParamsFromUrl = (url: string) => {
  // ex url : http://localhost:3000/play/shopify/account#customer_id=${customer_id}#shop=raphtestloyoly.myshopify.com
  const { searchParams } = new URL(document.URL)
  const blocks = url.split('#')
  let customerId = ''
  let shopName = ''
  const langCode = searchParams.get('langcode')
  if (blocks.length > 1) customerId = blocks[1].replace('customer_id=', '')
  if (blocks.length > 2) shopName = blocks[2].replace('shop=', '')
  if (searchParams.has('customer_id')) {
    customerId = searchParams.get('customer_id') || ''
  }
  if (searchParams.has('shop')) {
    shopName = searchParams.get('shop') || ''
  }
  return {
    customerId,
    shopName,
    langCode
  }
}

export default defineNuxtRouteMiddleware(async (to) => {
  const { setIsEmbeddedStore, fetchLocales } = useStoreStore()
  const { authenticateFromStore, logout } = useStoreAuth()

  const { customerId, shopName, langCode } = retrieveParamsFromUrl(to.fullPath)

  logout()
  const company = await useStoreStore().fetchStoreFromName(shopName)
  useStoreStore().langCode = langCode
  if (company) {
    const companyId = company.company_id
    useStoreApp().companyId = companyId
    setIsEmbeddedStore(companyId, true)

    useStoreCompany().fetchCompany(companyId)
    await fetchLocales(companyId)
    try {
      await authenticateFromStore(companyId, customerId)
    } catch (error) {
      const { response } = error as ApiErrorClass
      if (response?._data?.code === StatusMessageBlacklisted) {
        return abortNavigation({ statusCode: 403, statusMessage: AbortNavigationMessage })
      }
    }
  } else {
    return navigateTo('https://www.loyoly.io', { external: true })
  }
})
